<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <!-- Table Container Card -->
    <b-card no-body>
    
      <b-card-body>
        <b-row>
          

          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="suppliers"
              label="name"
              class="w-100"
              placeholder="Supplier"
              @input="resetCategory()"
              v-model="supplierData"
              :clearable="false"
            >
            <template #selected-option="{ name }">
                <span v-if="name.length < 23">{{name}}</span>
                <span v-if="name.length >= 23">{{name.substring(0, 23) + '...'}}</span>
              </template>
            </v-select>
          </b-col>

          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="categories"
              label="name"
              class="w-100"
              placeholder="Category"
              @input="resetSubCategory()"
              
              v-model="categoryData"
              :clearable="false"
            >
            <template #selected-option="{ name }">
                <span v-if="name.length < 23">{{ name }}</span>
                <span v-if="name.length >= 23">{{ name.substring(0, 23) + '...' }}</span>
              </template>
            </v-select>
          </b-col>

          <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sub_categories"
                label="name"
                class="w-100"
                placeholder="Sub Category"
                @input="filterTable"
                v-model="subcategoryData"
                :clearable="false"
              >
              <template #selected-option="{ name }">
                  <span v-if="name.length < 23">{{ name }}</span>
                  <span v-if="name.length >= 23">{{ name.substring(0, 23) + '...' }}</span>
                </template>
              </v-select>
            </b-col>

            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">

              <b-button
                  variant="danger"
                  class="mobile-margin-bottom mr-1"
                  @click="resetFilter()"
                >
                  <span class="text-nowrap">Reset</span>
                </b-button>

            </b-col>

          

          
          
        </b-row>
      </b-card-body>
    </b-card>

    
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="9"
            class="mobile_tab_max_width_flex all_btn_tab"
          >
            <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem "
                placeholder="Search..."
                @input="filterTable"
                autocomplete="off"
              />

              <b-button
                :disabled="(items.length > 0) ? false : true"
                variant="success"
                class="mr-1 mobile-margin-bottom" 
                @click="downloadItem('pdf')"
                v-if="checkPermission($route.name, 'PDF')"
              >
                  <span class="text-nowrap">PDF</span>
              </b-button>

              <b-button
                  :disabled="(items.length > 0) ? false : true"
                  variant="secondary"
                  class="mr-1 mobile-margin-bottom"
                  @click="downloadItem('excel')"
                  v-if="checkPermission($route.name, 'Excel')"
              >
                  <span class="text-nowrap">Excel</span>
              </b-button>

              <b-button
                variant="primary"
                class="mobile-margin-bottom mr-1"
                :disabled="selected.length == 0"
                @click="openAssignSiteModel"
                v-if="checkPermission($route.name, 'Assign Project Site')"
              >
                <span class="text-nowrap">Assign Project Site</span>
              </b-button>


              <b-button
                v-if="checkPermission($route.name, 'Add')"
                variant="warning"
                class="mobile-margin-bottom mr-1"
                :to="{ name: 'add-supplier-item', params: { supplier_id: supplierData != null ? supplierData._id : 'all-suppliers', category_id: categoryData != null ? categoryData._id : 'all-categories', sub_category_id: subcategoryData != null ? subcategoryData._id : 'all-sub-categories' } }"
              >
                <span class="text-nowrap">Add</span>
              </b-button>
              
              <ImportItems v-if="checkPermission($route.name, 'Import Items')"/>
              
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative sTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >

        <template #head(checkbox)="items">
            <b-form-checkbox class="custom-control-warning" v-model="selectAll" @change="selectall" />
        </template>

        <template #cell(checkbox)="items">
            <b-form-checkbox v-model="selected" :value="items.item._id" class="custom-control-warning my-1" />
        </template>

        <!-- Column: User -->
        <template #cell(info)="items">             
            <feather-icon
              :icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
              @click="items.toggleDetails"
            />
        </template>

        <template #cell(name)="items">
           
           <span class="wordBreak" @click="items.toggleDetails">{{ items.item.name | capitalize}}</span>
        
       </template>

       <template #cell(supplier_name)="items">
           
             <span class="wordBreak" @click="items.toggleDetails"> {{ items.item.supplier.name | capitalize }}</span>
        
        </template>

        
        <template #cell(status)="items">
           
            <b-badge
              pill
              :variant="variantColor(items.item.status)"
              class="text-capitalize"
              @click="items.toggleDetails"
            >
              {{ items.item.status}}
            </b-badge>
         
        </template>

        <template #cell(unit_price)="items">
           
            <span  @click="items.toggleDetails">${{items.item.unit_price | currency}}</span>
         
        </template>

        <template #cell(actions)="items">

          <!-- <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            

            <b-dropdown-item :to="{ name: 'edit-supplier-item', params: { id: items.item._id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="statusChange(items.item)">
              <feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
              <span class="align-middle ml-50" v-if="items.item.status == 'active'">Inactive</span>
              <span class="align-middle ml-50" v-if="items.item.status == 'inactive'">Active</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteItem(items.item._id)">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            

            
          </b-dropdown>  -->

           <b-link  :to="{ name: 'edit-supplier-item', params: { id: items.item._id } }"
            v-b-tooltip.hover.v-warning
            title="Edit"
            variant="outline-warning"
            v-if="checkPermission($route.name, 'Edit')"
          >
              <feather-icon icon="EditIcon" class="mediumSize mr-1"/>
              
          </b-link>

            <b-link v-b-tooltip.hover.v-warning :title= "items.item.status == 'active' ? 'Mark Inactive' : 'Mark Active'" variant="outline-warning" @click="statusChange(items.item)" v-if="checkPermission($route.name, 'Change Status')">
                <feather-icon :icon="items.item.status == 'active' ? 'CheckCircleIcon' : 'CircleIcon'" class="mediumSize mr-1" />	
            </b-link>

           <b-link  @click="deleteItem(items.item._id)" 
            v-b-tooltip.hover.v-warning
            title="Delete"
            variant="outline-warning"
            v-if="checkPermission($route.name, 'Delete')"
          >
              <feather-icon icon="Trash2Icon" class="mediumSize mr-1"/>
              
          </b-link>
        </template>

        <template #row-details="items">
            <div class="p-1 px-2 fbDetail mb-1">
                <b-row>

                  <b-col cols="12" xl="12" >
                      <table class="mt-2 mt-xl-0 w-100">
                          <tr class="mb-1">
                              <th class="pb-50" style="width: 16%">
                                <div class="d-flex align-items-center">
                            
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">
                                      Internal Item Code 
                                    </h6>
                                  <!--  <small>Monthly Sales</small> -->
                                  </div>
                                </div>
                              </th>
                              <td class="pb-50 mediumFonts wordBreak" style="width: 17%;padding-top: 8px;">
                                {{ items.item.code | capitalize }}
                              </td>

                              

                              <th class="pb-50" style="width: 16%">
                                  <div class="d-flex align-items-center">
                                
                                    <div class="ml-1">
                                      <h6 class="mb-0 mediumFonts">
                                        Category 
                                      </h6>
                                    <!--  <small>Monthly Sales</small> -->
                                    </div>
                                  </div>
                              </th>
                              <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                {{ items.item.category.name | capitalize }}
                              </td>

                              <th class="pb-50" style="width: 16%">
                                <div class="d-flex align-items-center">
                              
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">
                                      Sub Category 
                                    </h6>
                                  <!--  <small>Monthly Sales</small> -->
                                  </div>
                                </div>
                              </th>
                              <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                  <span v-if="items.item.sub_category != null">{{ items.item.sub_category.name | capitalize }} </span>
                                  <span v-else>{{ 'N/A' }}</span>
                              </td>
                            </tr>



                            <tr class="mb-1">

                              <!-- <th class="pb-50" style="width: 16%">
                                <div class="d-flex align-items-center">
                              
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">
                                      Packing 
                                    </h6>
                                  </div>
                                </div>
                              </th>
                              <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                    {{ items.item.packing }}
                              </td> -->


                              <th class="pb-50" style="width: 16%">
                                  <div class="d-flex align-items-center">
                              
                                    <div class="ml-1">
                                      <h6 class="mb-0 mediumFonts">
                                        ADDED ON
                                      </h6>
                                    <!--  <small>Monthly Sales</small> -->
                                    </div>
                                  </div>
                              </th>
                              <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                  <span class="wordBreak">{{sitedateTime(items.item.created_at,'DD MMM YYYY','HH:mm')}}</span>
                              </td>

                              <th class="pb-50" style="width: 16%">
                                    <div class="d-flex align-items-center">
                              
                                      <div class="ml-1">
                                        <h6 class="mb-0 mediumFonts">
                                          Project Sites 
                                        </h6>
                                      <!--  <small>Monthly Sales</small> -->
                                      </div>
                                    </div>
                              </th>
                              <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">

                                  <span v-b-tooltip.hover.v-primary.top :title="getFromAsgSite(items.item.sites)" v-if="items.item.sites.length > 0 && getFromAsgSite(items.item.sites).split(',').length > 3">
                                    {{ getFromAsgSite2(items.item.sites)[0] }}
                                    <b-badge v-if="getFromAsgSite2(items.item.sites)[1] != ''" pill variant="primary"> +{{ getFromAsgSite2(items.item.sites)[1] }} </b-badge>
                                  </span>
                                  <span v-if="items.item.sites.length > 0 && getFromAsgSite(items.item.sites).split(',').length <= 3">
                                    {{ getFromAsgSite2(items.item.sites)[0] }}
                                  </span>
                                  <span v-if="items.item.sites.length == 0">{{ 'N/A' }}</span>
                              </td>

                              
                          </tr>

                            <tr class="mb-1">
                                <th class="pb-50" style="width: 16%">
                                  <b-button
                                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                      variant="outline-secondary"
                                      @click="items.toggleDetails"
                                      size="sm"
                                      class="ml-1"
                                    >
                                      Hide Detail
                                    </b-button>
                                </th>
                                <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                    
                                </td>
                                <th class="pb-50" style="width: 16%">
                                </th>
                                <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                    
                                </td>
                                <th class="pb-50" style="width: 16%">
                                </th>
                                <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                    
                                </td>
                          </tr>
                          
                      </table>
                  </b-col>

                </b-row>
            </div>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
        class="assignCF"
        id="assign_site"
        ref="assign_site"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Assign Project Site"
        @ok="assignSite"
        no-close-on-backdrop
    >

        <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
            <div class="alert-body">
                {{ error_message }}
            </div>
        </b-alert>

        <b-form>
            <b-row>
                <b-col cols="12" md="12" class="mb-md-0 mb-2">
                    <b-form-group label="Project Site" class="required">
                      
                        <v-select
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="assign_sites"
                            label="site_name"
                            class="w-100"
                            placeholder="Select Project Site"
                            v-model="selected_sites"
                        >
                        </v-select>
                    </b-form-group>
                </b-col>
            </b-row>

        </b-form>
    </b-modal>


  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormCheckbox, BForm,
  BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb,BCardBody, VBTooltip, BAlert, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'
import ImportItems from './ImportItems.vue';
import Bus from "../../../event-bus";
export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BBreadcrumb,
    BFormCheckbox,
    BForm,
    BFormGroup,
    ImportItems
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  data() {
    return {

        tableColumns: [
          { key: 'checkbox', label: '', sortable: false, thStyle: { width: '1%' } },
          { key: 'info', label: 'INFO', sortable: false, thStyle: { width: '1%'} },
          { key: 'name', label: 'Item Name', sortable: true, thStyle: { width: '20%' } },
          { key: 'packing', label: 'Packing', sortable: true, thStyle: { width: '9%' } },
          { key: 'supplier_name', label: 'Supplier Name', sortable: false , thStyle:  {width: '15%'}},
          { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '15%'}},
          { key: 'unit_price', label: 'Rate/ Unit Price', sortable: true , thStyle:  {width: '20%'}},
          { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '17%'}},
        ],
        
        items:[],
        isSortDirDesc: false,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
       
        
        suppliers:[],
        supplierData:null,
        categories: [],
        categoryData: null,
        sub_categories: [],
        subcategoryData: null,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:0,
        to:0,
        selectAll: false,
        selected: [],
        popshowDismissibleAlert:false,
        error_message:null,
        assign_sites:[],
        selected_sites:null
    }
  },
  methods : {
    
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Inventory'
      },{
        to:null,
        text: 'Settings'
      }, {
        to: null,
        text: 'Items Management',
        active: true
      }];
      return item;
    },
    
    dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                     role:this.$store.getters.currentUser.role,
                     om_sites:this.$store.getters.currentUser.om_sites,
                     
                     supplier:this.supplierData ? this.supplierData._id : null,
                     category: this.categoryData ? this.categoryData._id : null,
                     sub_category: this.subcategoryData ? this.subcategoryData._id : null,
                     site:null
                   },
                   api: '/api/suppliers-items-list'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              
              this.selected = [];
              this.selectAll = false;

              this.items = this.$store.getters.getResults.data.docs;
              this.totalRecords = this.$store.getters.getResults.data.total;
              this.perPage = this.$store.getters.getResults.data.limit;
              this.from = this.$store.getters.getResults.data.from
              this.to = this.$store.getters.getResults.data.to
              return this.items;
          }
      });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    statusChange(item){
      if (item.status == 'active') {
          var msg = 'Are you sure want to inactivate this record?';
          var message='Item Inactivated Successfully.';
      } 
      else{
          var msg = 'Are you sure want to activate this record?';
          var message='Item Activated Successfully.';
      }

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: item._id,
                          status:item.status
                        },
                    api:"/api/change-supplier-item-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.errorAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
             
          })
    },
    deleteItem(id){
      var msg = 'Are you sure want to delete this record?';
      var message='Item Deleted Successfully.';

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: id,
                          status:'deleted'
                        },
                    api:"/api/change-supplier-item-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.errorAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
             
          })
    },
    getSuppliers(){
      return this.$store.dispatch(POST_API, {
         data:{
              role:this.$store.getters.currentUser.role,
             
         },
         api: '/api/all-suppliers'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.suppliers = this.$store.getters.getResults.data;
              var obj = {
                  _id:'all-suppliers',
                  name:'All Suppliers'
              }
              this.suppliers.unshift(obj);
              return this.suppliers;
          }
      });
    },
    getCategories() {
      return this.$store.dispatch(POST_API, {
        data: {
          role: this.$store.getters.currentUser.role,
          
          supplier: this.supplierData ? this.supplierData._id : null
        },
        api: '/api/all-unique-suppliers-categories'
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
          } else {
            this.categories = this.$store.getters.getResults.data;
            var obj = {
              _id: 'all-categories',
              name: 'All Categories'
            }
            this.categories.unshift(obj);
            return this.categories;
          }
        });
    },
    getSubCategories() {
      return this.$store.dispatch(POST_API, {
        data: {
          role: this.$store.getters.currentUser.role,
          
          supplier: this.supplierData ? this.supplierData._id : null,
          category: this.categoryData ? this.categoryData._id : null
        },
        api: '/api/all-unique-suppliers-sub-categories'
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
          } else {
            this.sub_categories = this.$store.getters.getResults.data;
            var obj = {
              _id: 'all-sub-categories',
              name: 'All Sub Categories'
            }
            this.sub_categories.unshift(obj);
            return this.sub_categories;
          }
        });
    },
    updateSite(){
      this.getSuppliers();
      this.supplierData = {
        _id: 'all-suppliers',
        name: 'All Suppliers'
      };

      this.getCategories();
      this.categoryData = {
        _id: 'all-categories',
        name: 'All Categories'
      };

      this.getSubCategories();
      this.subcategoryData = {
        _id: 'all-sub-categories',
        name: 'All Sub Categories'
      };

      this.filterTable();

    },
    resetCategory(){

      this.getCategories();
      this.categoryData = {
        _id: 'all-categories',
        name: 'All Categories'
      };

      this.getSubCategories();
      this.subcategoryData = {
        _id: 'all-sub-categories',
        name: 'All Sub Categories'
      };

      this.filterTable();

    },
    resetSubCategory() {
      this.getSubCategories();
      this.subcategoryData = {
        _id: 'all-sub-categories',
        name: 'All Sub Categories'
      };

      this.filterTable();

    },
    async setParamsValue(){
      
      await this.getSuppliers();
      
      if (this.$route.params.supplier_id != '') {
        
        var supplierData = this.suppliers.filter(item => {return item._id == this.$route.params.supplier_id});  
        this.supplierData = supplierData.length > 0 ? supplierData[0] : this.suppliers[0] //this.supplierData;  
      }

      await this.getCategories();

      console.log(this.$route.params.category_id);
      if (this.$route.params.category_id != '') {
        
        var categoryData = this.categories.filter(item => { return item._id == this.$route.params.category_id });
        this.categoryData = categoryData.length > 0 ? categoryData[0] : this.categories[0] //this.categoryData;
      }

      await this.getSubCategories();

      if (this.$route.params.sub_category_id != '') {

        var subcategoryData = this.sub_categories.filter(item => { return item._id == this.$route.params.sub_category_id });
        this.subcategoryData = subcategoryData.length > 0 ? subcategoryData[0] : this.sub_categories[0] //this.categoryData;
      }

      this.filterTable();

    },
    selectall(e) {

      this.selected = [];

      if (e) {
        var selected = [];

        this.items.forEach(function (item) {
            selected.push(item._id);
        })

        this.selected = selected;

      }
    },
    openAssignSiteModel() {
      this.popshowDismissibleAlert = false;
      this.assign_sites = [];
      this.selected_sites = null;
      this.allInventorySites();
      this.$refs['assign_site'].show();
    },
    allInventorySites() {
      return this.$store.dispatch(POST_API, {
        data: {
          role: this.$store.getters.currentUser.role,
        },
        api: '/api/all-inventory-sites'
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
          } else {
            this.assign_sites = this.$store.getters.getResults.data;

            return this.assign_sites;
          }
        });
    },
    assignSite(e){
      e.preventDefault();
      return this.$store.dispatch(POST_API, {
        data: {
          
          ids: this.selected.reverse(),
          site:this.selected_sites
        },
        api: '/api/assign-site-to-items'
      })
      .then(() => {
        if (this.$store.getters.containsErrors) {
          this.error_message = this.$store.getters.getErrors;
          this.popshowDismissibleAlert = true;
        } else {
          this.popshowDismissibleAlert = false;
          var message = this.$store.getters.getResults.message;
          this.successAlert()
            .then((result) => {
              this.$refs['assign_site'].hide();
              this.filterTable();
            });
        }
      });
    },
    getFromAsgSite(site) {
      if (site.length == 0) {
        return 'N/A';
      } else {
        var arr = [];
        for (var i = 0; i < site.length; i++) {
          if(this.$store.getters.currentUser.role == 'administrator'){
            arr.push(site[i].site_name);
          }else if (this.$store.getters.currentUser.om_sites.indexOf(site[i]._id) >= 0) {
            arr.push(site[i].site_name);
          }
        }

        return arr.join(', ');

      }
    },
    getFromAsgSite2(site) {
      if (site.length == 0) {
        return 'N/A';
      } else {
        var arr = [];
        for (var i = 0; i < site.length; i++) {
          if (this.$store.getters.currentUser.role == 'administrator') {
            arr.push(site[i].site_name);
          } else if (this.$store.getters.currentUser.om_sites.indexOf(site[i]._id) >= 0) {
            arr.push(site[i].site_name);
          }
        }

        if (arr.length > 3) {
          return [arr[0] + ', ' + arr[1] + ', ' + arr[2], (arr.length - 3)]
        } else {
          return [arr.join(', '), ''];
        }

      }
    },
    resetFilter(){
      this.updateSite();
    },
    downloadItem(type){
     
      var keyword = this.searchQuery;
      var sortBy = this.sortBy;
      var sortDirection = this.isSortDirDesc;
      var role = this.$store.getters.currentUser.role;

      var om_sites = [];

      for (var i = 0; i < this.$store.getters.currentUser.om_sites.length; i++) {
        om_sites.push(this.$store.getters.currentUser.om_sites[i]);
      }


      var supplier = this.supplierData ? this.supplierData._id : "null";
      var category = this.categoryData ? this.categoryData._id : "null";
      var sub_category =  this.subcategoryData ? this.subcategoryData._id : "null";
      var site =  null;

      var params = "keyword=" + keyword + "&sortBy=" + sortBy + "&sortDirection=" + sortDirection + "&site=" + site + "&organization=" + this.$store.getters.currentUser.organization + 
      "&role=" + role + "&om_sites=" + om_sites.join(',') + "&user_id=" + this.$store.getters.currentUser._id + "&supplier="+ supplier + "&category=" + category + "&sub_category=" + sub_category;

      var baseurl = "";

      if (type == 'pdf') {
        baseurl = process.env.VUE_APP_SERVER_URL + "/api/inventory-items-pdf?" + params;
      } else {
        baseurl = process.env.VUE_APP_SERVER_URL + "/api/inventory-items-excel?" + params;
      }

      console.log(baseurl);
      window.open(baseurl, '_blank');

      
    }
  },
  mounted(){
    this.setParamsValue();

    Bus.$on('refresh_table', () => {
      this.filterTable();
    })
  }
  
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
